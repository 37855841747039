import React from 'react'; 
import HtmlToReact from 'html-to-react';


const CafeCryptoContent = () => {

  const htmlInput = `

    <!DOCTYPE html><!--  This site was created in Webflow. https://www.webflow.com  -->
    <!--  Last Published: Mon Apr 17 2023 14:52:11 GMT+0000 (Coordinated Universal Time)  -->
    <html data-wf-page="64119055c2ce0652f9c28dc9" data-wf-site="64119055c2ce06817ac28dc8">
    <head>
    <meta charset="utf-8">
    <title>LiveProject</title>
    <meta content="width=device-width, initial-scale=1" name="viewport">
    <meta content="Webflow" name="generator">
    <link href="cafecrypto/css/normalize.css" rel="stylesheet" type="text/css">
    <link href="cafecrypto/css/webflow.css" rel="stylesheet" type="text/css">
    <link href="cafecrypto/css/liveproject-ca6de6.webflow.css" rel="stylesheet" type="text/css">
    <!-- [if lt IE 9]><script src="https://cdnjs.cloudflare.com/ajax/libs/html5shiv/3.7.3/html5shiv.min.js" type="text/javascript"></script><![endif] -->
    <script type="text/javascript">!function(o,c){var n=c.documentElement,t=" w-mod-";n.className+=t+"js",("ontouchstart"in o||o.DocumentTouch&&c instanceof DocumentTouch)&&(n.className+=t+"touch")}(window,document);</script>
    <link href="images/favicon.ico" rel="shortcut icon" type="image/x-icon">
    <link href="images/webclip.png" rel="apple-touch-icon">
    </head>
    <body class="body-2">
   
    <div class="section header">
      <div class="container header">
        <div class="titre-1 header">
            <div class="text-block" style="font-weight:400px;">Café Crypto</div>
        </div>
        <div class="header_heading_title">
            <h1 class="header_heading" style="margin-bottom:-2rem;">Partagez vos connaissances sur la</h1>
            <h1 class="header_heading t2" style="background: linear-gradient(90deg, #5B8AF0 0%, #DC3F4D 100%);-webkit-background-clip: text;-webkit-text-fill-color: transparent;">Blockchain, les Cryptos et le Web 3</h1>
        </div>
        <div class="" style="font-size:18px; color:white; text-align:center; font-weight:100;">Aidez notre/votre communauté à s’instruire et être à jours <br>vis-à-vis de l&#x27;écosystème des cryptomonnaies</div>
        <a href="new-speaker" class="button header_buton w-button" style="margin-top:2rem; margin-bottom:-2rem;">Devenez Speaker</a>
        
        <div class="header_video_section" style="width:100%;">
            <div style="padding-top:56.17021276595745%;" class="w-embed-youtubevideo heder_video"><iframe src="https://www.youtube.com/embed/_xpLQDfrrSw?rel=0&amp;controls=1&amp;autoplay=1&amp;mute=1&amp;start=0" frameborder="0" style="position:absolute;left:0;top:0;width:100%;height:100%; border-radius:45px;" allow="autoplay; encrypted-media" allowfullscreen="" title="Sur IZICHANGE nos tarifs sont sans frais"></iframe></div>
        </div>   
          
        <div class="light-effect"></div>

        <div class="header_title_cta">
            <div class="heder_title_01">Éduquez et sensibilisez sur les divers risques et opportunités</div>
            <div class="header_title_02">liés à la technologie Blockchain <br>aux Cryptomonnaies <br>et  au Web 3</div>
        </div>
      </div>
    </div>

    <div class="section who wf-section">
        <div class="who container">
           <div class="who_title">
                <div class="who_title_01">
                    <div class="who_title_text_01">Vous êtes un professionnel ou un expert</div>
                    <div class="who_title_text_02">du domaine des cryptomonnaies ?</div>
                </div>
                <div class="who_title_02">Partagez vos connaissances en tant que <strong>Speaker</strong> sur les Lives de Café Crypto.</div>
            </div>
            <div class="who_crypto_section">
                <div class="who_crypto_section_img01">
                    <img src="cafecrypto/images/crypto-img-adv-3.png" loading="lazy" width="663" sizes="(max-width: 479px) 83vw, (max-width: 767px) 81vw, (max-width: 991px) 590px, 49vw" srcset="cafecrypto/images/crypto-img-adv-3-p-500.png"  alt="" class="image-7">
                </div>
                <div class="who_crypto_section_img02">
                    <div class="who_crypto_img_01"><img src="cafecrypto/images/Who-1-1.png" loading="lazy" width="585" sizes="(max-width: 479px) 83vw, (max-width: 767px) 79vw, (max-width: 991px) 585px, 44vw" srcset="cafecrypto/images/Who-1-1-p-500.png 500w, cafecrypto/images/Who-1-1-p-800.png 800w, cafecrypto/images/Who-1-1-p-1080.png 1080w, cafecrypto/images/Who-1-1.png 1334w" alt=""></div>
                    <div class="who_crypto_img_02"><img src="cafecrypto/images/Who-2-1.png" loading="lazy" width="590" sizes="(max-width: 479px) 83vw, (max-width: 767px) 79vw, (max-width: 991px) 590px, 44vw" srcset="cafecrypto/images/Who-2-1-p-500.png 500w, cafecrypto/images/Who-2-1-p-800.png 800w, cafecrypto/images/Who-2-1-p-1080.png 1080w, cafecrypto/images/Who-2-1.png 1334w" alt=""></div>
                </div>
            </div>
            <div class="who-condition">
                <div class="who-condition-01">
                  
                    <span class="text" style="">Condition à Remplir</span>
                </div>
                <div class="who-condition-02">
                <div class="text-2">Avoir une certaine base de connaissances et d&#x27;expérience dans le domaine de la blockchain, des cryptos et du web 3</div>
                </div>
                <div class="who-condition-03">
                <div class="text-3">L’univers de la blockchain, des cryptos et de la web 3 n’est plus un secret pour vous ?
                    Vous vous sentez à l’aise lors des débats ou discussions ayant trait à cet univers passionnant que constituent les cryptomonnaies ? Café Crypto est fait pour vous.</div>
                </div>
            </div>
        </div>
    </div>
    <div class="section advantage wf-section" style="margin-top:-5rem;">
        <div class="container advantage">
        <div class="advantage-section">
            <div class="w-layout-grid section-avantage-grid">
                <div id="w-node-f6203e39-324d-6429-ce6e-65bfb3a436b7-f9c28dc9" class="title-advantage-section">
                    <div class="advantage-title avantageStyle">
                    <div class="text-4">Quels sont les Avantages ?</div>
                    </div>
                    <div class="advantage-title">
                    <div class="vous-tes-un-professionnel-ou-un-expert-du-domaine-des-cryptomonnaies-partagez-vos-connaissances-en-tant-que-speaker-sur-les-lives-de-caf-crypto">Être speaker sur Café Crypto, c’est bénéficier d’une multitude d’avantages, exclusifs et adaptés <br>à chaque hôte.</div>
                    </div>
                </div>
                <div id="w-node-f6203e39-324d-6429-ce6e-65bfb3a436be-f9c28dc9" class="img-adv-01"><img src="cafecrypto/images/Adv-01.png" loading="lazy" sizes="(max-width: 479px) 83vw, (max-width: 767px) 42vw, (max-width: 991px) 44vw, 41vw" srcset="cafecrypto/images/Adv-01-p-500.png 500w, cafecrypto/images/Adv-01.png 660w" alt=""></div>
                <div id="w-node-f6203e39-324d-6429-ce6e-65bfb3a436c0-f9c28dc9" class="img-adv-02"><img src="cafecrypto/images/Adv-02.png" loading="lazy" sizes="(max-width: 479px) 83vw, (max-width: 767px) 42vw, (max-width: 991px) 44vw, 41vw" srcset="cafecrypto/images/Adv-02-p-500.png 500w, cafecrypto/images/Adv-02.png 660w" alt=""></div>
            </div>
            <div class="w-layout-grid grid-6 section-adventage-img-2">
                <div style="margin-top:2rem;" id="w-node-_8cf73968-0ede-7c76-fddc-1800cdb8793d-f9c28dc9" class="img-adv-03"><img src="cafecrypto/images/Adv-03.png" loading="lazy" sizes="(max-width: 479px) 83vw, (max-width: 767px) 42vw, (max-width: 991px) 43vw, 41vw" srcset="cafecrypto/images/Adv-03-p-500.png 500w, cafecrypto/images/Adv-03.png 660w" alt=""></div>
                <div id="w-node-_38812f5a-e85c-8197-9f66-598ce3a1708a-f9c28dc9" class="img-adv-04"><img src="cafecrypto/images/Adv-04.png" loading="lazy" sizes="(max-width: 479px) 83vw, (max-width: 767px) 42vw, (max-width: 991px) 43vw, 41vw" srcset="cafecrypto/images/Adv-04-p-500.png 500w, cafecrypto/images/Adv-04.png 660w" alt=""></div>
            </div>
        </div>
        </div>
    </div>
    <div class="section live-section wf-section">
        <div class="live-section container">
        <div class="container-3">
            <div class="section-live-title">
                <div class="live-title">
                    <div class="live-title-02">
                    <div class="text-5">Nos Lives</div>
                    </div>
                    <div class="live-title-02">
                    <div class="text-6">Café Crypto</div>
                    </div>
                </div>
                <div class="live-title-02">
                    <div class="text-7">Devenez un véritable acteur du changement intellectuel et financier de milliers d&#x27;amateurs et passionnées de cet écosystème. </div>
                </div>
            </div>
            <div class="w-layout-grid grid live"><img src="cafecrypto/images/vid-2.png" loading="lazy" height="" sizes="(max-width: 479px) 85vw, (max-width: 760px) 80vw, 608px" srcset="cafecrypto/images/vid-2-p-500.png 500w, cafecrypto/images/vid-2.png 608w" alt="" class="frame-7471-1 img-live1"><img src="cafecrypto/images/vid-1.png" loading="lazy" height="" id="w-node-eae93669-eb5c-0dca-ff6a-9f42319a89b9-f9c28dc9" srcset="cafecrypto/images/vid-1-p-500.png 500w, cafecrypto/images/vid-1-p-800.png 800w, cafecrypto/images/vid-1.png 810w" sizes="(max-width: 810px) 100vw, 810px" alt="" class="frame-7471-1"><img src="cafecrypto/images/vid-3.png" loading="lazy" height="" id="w-node-eae93669-eb5c-0dca-ff6a-9f42319a89ba-f9c28dc9" srcset="cafecrypto/images/vid-3-p-500.png 500w, cafecrypto/images/vid-3.png 608w" sizes="(max-width: 479px) 100vw, (max-width: 760px) 80vw, 608px" alt="" class="frame-7471-1 img-live-2"></div>
            <div class="live-title-02">
            <div class="text-8">
                <div class="text-8 text-8"><span class="pr-te-embarquer-dans-cette-nouvelle-et-passionnante-aventure-qu-est-le-caf-crypto-0">Prêt(e) à embarquer</span><span class="pr-te-embarquer-dans-cette-nouvelle-et-passionnante-aventure-qu-est-le-caf-crypto-1"> dans cette nouvelle et <br>passionnante aventure qu’est le </span><span class="pr-te-embarquer-dans-cette-nouvelle-et-passionnante-aventure-qu-est-le-caf-crypto-0">Café Crypto ?</span></div>
            </div>
            </div>
            <div class="live-cta-bottom">
            <a href="new-speaker" class="commencer">Devenez Speaker sur Café Crypto</a>
            </div>
        </div>
        </div>
    </div>
    <div class="section client wf-section">
        <div class="container">
        <div class="client-section">
            <div class="client-cta">
            <div class="client-cta-1">
                <div class="vous-tes-d-butant-dans-l-cosyst-me-des-cryptomonnaies">Vous êtes débutant dans
                l’écosystème des
                cryptomonnaies ?</div>
            </div>
            <div class="client-cta-2">
                <div class="client-cta-1">
                <div class="recevez-les-conseils-des-professionnels-et-expert-du-domaine">Recevez les conseils des professionnels
                    et experts du domaine </div>
                </div>
            </div>
            </div>
            <div class="client-advantage">
                <div class="w-layout-grid grid-5 section-client-adventage">
                    <div id="w-node-_9e0a756c-5267-5d3b-7e26-3e13c2d84bfd-f9c28dc9" class="client-div-2 section_client"><img src="cafecrypto/images/Enc-1-1.png" loading="lazy" width="417" sizes="(max-width: 767px) 83vw, 417px" srcset="cafecrypto/images/Enc-1-1-p-500.png 500w, cafecrypto/images/Enc-1-1-p-800.png 800w, cafecrypto/images/Enc-1-1.png 860w" alt="" class="image-3 client"></div>
                    <div id="w-node-_73a43c8f-70ee-f8b1-aad8-569f29a4e88d-f9c28dc9" class="client-div-2"><img src="cafecrypto/images/Enc-2-1.png" loading="lazy" sizes="(max-width: 479px) 83vw, (max-width: 767px) 90vw, (max-width: 945px) 91vw, 860px" srcset="cafecrypto/images/Enc-2-1-p-500.png 500w, cafecrypto/images/Enc-2-1-p-800.png 800w, cafecrypto/images/Enc-2-1.png 860w" alt=""></div>
                    <div id="w-node-_2f7494fc-0bf0-2c10-698a-2b4469218c12-f9c28dc9"><img src="cafecrypto/images/Enc-3-1.png" loading="lazy" sizes="(max-width: 479px) 83vw, (max-width: 767px) 90vw, (max-width: 945px) 91vw, 860px" srcset="cafecrypto/images/Enc-3-1-p-500.png 500w, cafecrypto/images/Enc-3-1-p-800.png 800w, cafecrypto/images/Enc-3-1.png 860w" alt=""></div>
                </div>
            </div>
            <a href="new-speaker" class="button-2 client-adanv-butom w-button"> Devenez Speaker sur Café Crypto </a>
        </div>
        </div>
    </div>
    
    <script src="https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=64119055c2ce06817ac28dc8" type="text/javascript" integrity="sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0=" crossorigin="anonymous"></script>
    <script src="js/webflow.js" type="text/javascript"></script>
    <!-- [if lte IE 9]><script src="https://cdnjs.cloudflare.com/ajax/libs/placeholders/3.0.2/placeholders.min.js"></script><![endif] -->
    </body>
    </html>
`;

  const HtmlToReactParser = HtmlToReact.Parser;
  const htmlToReactParser = new HtmlToReactParser();
  const reactElement = htmlToReactParser.parse(htmlInput);
  React.useEffect(() => {    
    if (document.getElementById('footerCustom')) 
       document.getElementById('footerCustom').style.display = 'none';   
    }, [])
    
        return (
            <div>
                {reactElement}
            </div>
        );
    }

export default CafeCryptoContent;
